import axios from "axios";
import { BASE_URL } from "../../variables";
import { sessionExpireTimeout } from "../../Redux/actions/utility-actions";

export const getMarketVisitReportApi = (date, userId) => async (dispatch) => {
  const options = {
    url: `${BASE_URL}/services/data/v56.0/query?q=Select id,SaveFormDate__c, Have_you_visited_a_WSS__c, WSS_Name_Visited__c, Key_Observations_Issues_faced_by_WSS__c, Are_competition_products_WSS__c,Darpan_bills_dealers_visited__c,Names_Dealers_Darpan_B_unavail__c,Dealer_Name_Visited__c,Key_Issues_faced_by_Dealer__c,Service_Or_Delivery_feedback__c,schemes_at_dealer_by_WSS__c,Scheme_settlement_comments__c,User_Name_Visited__c,Key_Issues_faced_by_User__c,Issue_Faced_While_Banking__c,Product_Quality_Issues__c,Product_Related_Opportunities__c,Any_Other_Feedback_Product__c,Observation_competition_activity_M_Visit__c,Competition_product_affecting_sales__c,Urgent_Escalation_Points__c,FeedbackOnUserLoyalty__c,FeedbackOnUserApp__c,FeedbackAtUserMeeting__c,ObsFromRedemptionCenter__c,User_Type__c,IsSavedDraft__c from Market_Visit_Report__c where SaveFormDate__c=${date} and OwnerId='${userId}'`,
    method: "get",
  };

  try {
    const response = await axios(options);
    if (response.status === 200) {
      return {
        success: true,
        data: response?.data?.records,
      };
    } else {
      throw Error("Something Went Wrong..!!");
    }
  } catch (err) {
    if (err?.response?.status === 401) {
      sessionExpireTimeout(dispatch);
    } else {
      return {
        success: false,
        msg: "Somthing Went Wrong..!!",
      };
    }
  }
};
