import React, { useEffect, useState } from "react";
import styles from "./MyDealer.module.css";
import { Link } from "react-router-dom";
import { getDealerApi } from "../../../services/MarketWorking/MyTeam/getDealerApi";
import _ from "lodash";
import { Button } from "react-bootstrap";
import GoogleMap from "./GoogleMap";
import { checkedInapi } from "../../../services/MarketWorking/MyTeam/checkInApi";
import VisitDetails from "./VisitDetails";
import { useSelector, connect } from "react-redux";
import { RiDirectionFill } from "react-icons/ri";
import TextField from "@mui/material/TextField";
import Autocomplete from "@mui/material/Autocomplete";
import "../../Common/SearchableSelect.css";
import CommonSnackbar from "../../Common/CommonSnackbar";
import Loader from "../../Common/Loader";
import { getZSMTerritoryApi } from "../../../services/zsm/getZSMTerritoryApi";
import { getUniqueListBy } from "../../Common/Function";
import Message from "../../Common/Message";
import { MdLocationOff } from "react-icons/md";
import { getDealerNameApi } from "../../../services/rmdm/getDealerNameApi";
import { getRmdmWssApi } from "../../../services/rmdm/getRmdmWssApi";
import PowerBi from "../../../Pages/PowerBi";

const clusterCodePlaceHolder = {
  SH3_Name__c: null,
  SH3_Code__c: null,
  Cluster_Description__c: "",
  CLUSTER_CODE__c: "",
};
const territoryAutoCompletePlaceHolder = {
  attributes: null,
  Id: null,
  SH2_Name__c: null,
  SH2_Code__c: null,
  Name: null,
  SH3_Code__c: null,
};

const dealerPlaceHolder = {
  attributes: null,
  Id: null,
  Dealer_Name__c: null,
  DealerClass__c: null,
  DealerType1__c: null,
  DealerLatitude__c: null,
  DealerLongitude__c: null,
  PrimaryTSICode__c: null,
  DealerCode__c: null,
  RecordTypeId: null,
};

const MyDealers = ({
  checkedInapi,
  getZSMTerritoryApi,
  getRmdmWssApi,
  getDealerNameApi,
}) => {
  const [dealerOption, setDealerOption] = useState([]);
  const [error, setError] = useState("");
  const [selectedTerritory, setSelectedTerritory] = useState(
    territoryAutoCompletePlaceHolder
  );
  const [selectLatLong, setSelectLatLong] = useState(dealerPlaceHolder);
  const [lat, setLat] = useState(18.516726);
  const [long, setLong] = useState(73.856255);
  const [dealer, setDealer] = useState("");
  const [sh3CodeForTerritory, setSH3CodeForTerritory] = useState("");
  const [territoryCheckIn, setTerritoryCheckIn] = useState("");
  const [successOpen, setSuccessOpen] = useState(false);
  const [failedOpen, setFailesOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const tname = useSelector((state) => state?.territory?.territoryDetails);
  const [currentLatitude, setCurrentLatitude] = useState(0);
  const [currentLongitude, setCurrentLongitude] = useState(0);
  const [territoryPickList, setTerritoryPickList] = useState([]);
  const [cluster, setCluster] = useState(clusterCodePlaceHolder);
  const [locationError, setLocationError] = useState(false);
  const [dealerCode, setDealerCode] = useState(0);
  const [accuracy, setAccuracy] = useState(0);
  const [currentAccuracyFactor, setCurrentAccuracyFactor] = useState(0);
  const territoryOption = useSelector(
    (state) => state?.territory?.territoryDetails
  );
  const [isDealerData, setIsDealerData] = useState("Loading");

  const userType = useSelector((state) =>
    state?.userType?.type != "Other"
      ? state?.userType?.type
      : state?.userDetails?.details?.Title
  );
  const clusterCodeOption = useSelector(
    (state) => state?.clusterCode?.clusterCodeList
  );
  const tourPlanDefaultData = useSelector(
    (state) => state?.upcomingTourPlan?.upcomingTourDetails?.records
  );

  //snackbar
  const handleOnClose = (reason) => {
    if (reason === "clickaway") {
      return;
    }
    setFailesOpen(false);
    setSuccessOpen(false);
  };

  // Get Selected Territory
  useEffect(() => {
    if (selectedTerritory) {
      const checkInTerritory = selectedTerritory?.Id;
      setTerritoryCheckIn(checkInTerritory);
    }
  }, [selectedTerritory]);

  // set lattitude and longittitude
  useEffect(() => {
    if (selectLatLong) {
      setLat(selectLatLong?.DealerLatitude__c);
      setLong(selectLatLong?.DealerLongitude__c);
      setDealer(selectLatLong?.Dealer_Name__c);
      setDealerCode(selectLatLong?.DealerCode__c);
      setAccuracy(parseInt(selectLatLong?.GPSAccuracy__c));
    }
  }, [selectLatLong]);

  // Dealer Name API call
  useEffect(() => {
    setIsDealerData("Loading")
    if (selectedTerritory?.SH2_Code__c !== null) {
      (async () => {
        let SH2_Code__c = selectedTerritory?.SH2_Code__c;
        const response = await getDealerApi(SH2_Code__c);
        if (response?.success) {
          if (response?.data?.length === 0) {
            setIsDealerData("false")
          } else {
            setDealerOption(response?.data?.Dealer);
            // setSelectedTerritory("");
            setIsDealerData("true")
          }
        } else {
          setError(response?.msg);
        }
      })();
    }
  }, [selectedTerritory]);

  // to get territory picklist when user is ZSM
  useEffect(() => {
    if (!_.isEmpty(sh3CodeForTerritory)) {
      (async () => {
        const response = await getZSMTerritoryApi(sh3CodeForTerritory);
        if (response?.success) {
          setTerritoryPickList(response?.data?.records);
        } else {
          setTerritoryPickList("");
        }
      })();
    }
  }, [sh3CodeForTerritory]);

  // On-submit checkIn button
  const checkIn = async (e) => {
    e.preventDefault();
    setLoading(true);
    console.log("Distance", dealer, distance + " acc " + accuracy +" tet "+ currentAccuracyFactor + " test " + 2 * (accuracy + currentAccuracyFactor) + 300 )
    if (currentLatitude === 0 && currentLongitude === 0) {
      setLoading(false);
      setFailesOpen(true);
      setError("Please turn on your location");
    } else if (_.isEmpty(territoryCheckIn) || _.isEmpty(dealer)) {
      setLoading(false);
      setError("Please select cluster, territory & dealer name!");
      setFailesOpen(true);
    } else if (
      distance <= 2 * (accuracy + currentAccuracyFactor) + 300 ||
      (_.isEmpty(lat) && _.isEmpty(long))
    ) {
      if (!_.isEmpty(dealer)) {
        let response;
        if (
          userType !== "MH3" &&
          userType !== "RBDM Collaborator" &&
          territoryCheckIn !== ""
        ) {
          response = await checkedInapi(
            "Visit Plan",
            territoryCheckIn,
            dealer,
            dealerCode,
            "",
            "",
            currentLatitude,
            currentLongitude,
            "Valid",
            lat,
            long,
            distance,
            accuracy,
            currentAccuracyFactor
          );
        } else if (userType === "MH3" || userType === "RBDM Collaborator") {
          response = await checkedInapi(
            "Visit Plan",
            cluster?.Id,
            dealer,
            dealerCode,
            "",
            "",
            currentLatitude,
            currentLongitude,
            "Valid",
            lat,
            long,
            distance,
            accuracy,
            currentAccuracyFactor
          );
        }
        setLoading(false);
        if (response?.success) {
          setSuccessOpen(true);
          // setCluster(clusterCodePlaceHolder);
          // setSelectLatLong(dealerPlaceHolder);
          // setSelectedTerritory(territoryAutoCompletePlaceHolder);
          // setSelectLatLong(dealerPlaceHolder);
        } else {
          setError(response?.msg);
          setFailesOpen(true);
          // setSelectedTerritory(territoryAutoCompletePlaceHolder);
          // setSelectLatLong(dealerPlaceHolder);
        }
      }
    } else {
      let response = await checkedInapi(
        "Visit Plan",
        territoryCheckIn,
        dealer,
        dealerCode,
        "",
        "",
        currentLatitude,
        currentLongitude,
        "Invalid",
        lat,
        long,
        distance,
        accuracy,
        currentAccuracyFactor
      );
      if (response?.success) {
        setError("Dealer Check-in Unsuccessful");
        // setSelectedTerritory(territoryAutoCompletePlaceHolder);
        // setSelectLatLong(dealerPlaceHolder);
      } else {
        setError(response?.msg);
        // setSelectedTerritory(territoryAutoCompletePlaceHolder);
        // setSelectLatLong(dealerPlaceHolder);
      }
      setLoading(false);
      setFailesOpen(true);
    }
  };

  // open default google map
  const showInMapClicked = () => {
    window.open(
      "https://maps.google.com?q=" + parseFloat(lat) + "," + parseFloat(long)
    );
  };

  console.log("Lat"+ lat +" Long " + long);

  //to get lat,lon from current location of user
  useEffect(() => {
    navigator.geolocation.getCurrentPosition(
      (position) => {
        setCurrentAccuracyFactor(position.coords.accuracy);
        setCurrentLatitude(position.coords.latitude);
        setCurrentLongitude(position.coords.longitude);
      },
      (error) => {
        console.log(error);
      }
    );
  }, [selectedTerritory, selectLatLong]);

  // checkIn validation
  function calcDistance(lat1, lon1, lat2, lon2) {
    const R = 6371; // Earth's radius in km
    const dLat = toRad(lat2 - lat1);
    const dLon = toRad(lon2 - lon1);
    const a =
      Math.sin(dLat / 2) * Math.sin(dLat / 2) +
      Math.cos(toRad(lat1)) *
      Math.cos(toRad(lat2)) *
      Math.sin(dLon / 2) *
      Math.sin(dLon / 2);
    const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));
    const d = R * c * 1000; // Distance in m
    return d;
  }

  // Helper function to convert degrees to radians
  function toRad(deg) {
    return deg * (Math.PI / 180);
  }

  const distance = calcDistance(currentLatitude, currentLongitude, lat, long);

  // For checking location access yes or not

  useEffect(() => {
    checkPermission();
  }, []);

  const checkPermission = async () => {
    try {
      const { state } = await navigator.permissions.query({
        name: "geolocation",
      });
      if (state === "denied") {
        setLocationError(true);
      }
    } catch (error) {
    }
  };


  useEffect(() => {
    setDefaultValues();
  }, []);

  const formatDate = (date) => {
    const d = new Date(date);
    const options = { weekday: 'short', month: 'short', day: '2-digit', year: 'numeric' };
    return d.toLocaleDateString('en-US', options);
  };

  const setDefaultValues = () => {

    var clusterFilterData = getUniqueListBy(clusterCodeOption, userType === "MH3" || userType === "RBDM Collaborator" ? "CLUSTER_CODE__c" : "SH3_Name__c");

    if (tourPlanDefaultData.length != 0) {
      const mtpCurrentDateData = tourPlanDefaultData.filter(item => formatDate(item.MTP_Date__c) === formatDate(new Date()));
      if (userType === "SH6" || userType === "SH5" || userType === "MH3" || userType === "RBDM Collaborator") {
        if (mtpCurrentDateData.length != 0 && mtpCurrentDateData[0].Cluster_es__c !== null) {
          const originalString = mtpCurrentDateData[0].Cluster_es__c;
          const parts = originalString.split(':');
          var defaultCluster = clusterFilterData.filter(item => item.CLUSTER_CODE__c === parts[1]);
          if (_.isEmpty(defaultCluster)){
            var defaultCluster = clusterFilterData.filter(item => item.SH3_Code__c === parts[1]);
          }
          setCluster(defaultCluster[0]);
          setSH3CodeForTerritory(parts[1])
        }
        if (mtpCurrentDateData.length != 0 && mtpCurrentDateData[0].MTP_Territory_es__r !== null) {
          const mtpTerritoryCode = mtpCurrentDateData[0].MTP_Territory_es__r.SH2_Code__c;
          var defaultTerritory = tname.filter(item => item.SH2_Code__c === mtpTerritoryCode);
          if (_.isEmpty(defaultTerritory)) {
            var defaultTerritory = territoryPickList.filter(item => item.SH2_Code__c == mtpTerritoryCode);
          }
          setSelectedTerritory(defaultTerritory[0]);
        }
      } else {
        if (mtpCurrentDateData.length != 0 && mtpCurrentDateData[0].MTP_Territory_es__r !== null) {
          const mtpTerritoryCode = mtpCurrentDateData[0].MTP_Territory_es__r.SH2_Code__c;
          var defaultTerritory = tname.filter(item => item.SH2_Code__c === mtpTerritoryCode);
          if (_.isEmpty(defaultTerritory)) {
            var defaultTerritory = territoryPickList.filter(item => item.SH2_Code__c == mtpTerritoryCode);
          }
          setSelectedTerritory(defaultTerritory[0]);
        }
      }
    }
  }

  return (
    <>
      <Loader loading={loading} />
      <VisitDetails />
      <div className={styles.myDealer__outerBox}>
        <div className={styles.myDealer__heading}>My Dealer</div>

        {userType === "SH7" && 
          <Autocomplete
            id="grouped-demo"
            className="myDealer__categorySelect"
            options={
              territoryOption?.length === 0
                ? [
                  {
                    SH2_Name__c: "Loading...",
                    SH2_Code__c: "",
                  },
                ]
                : getUniqueListBy(territoryOption, "SH2_Code__c")
            }
            getOptionLabel={(option) => {
              if (option?.SH2_Name__c) {
                return `${option?.SH2_Name__c} : ${option?.SH2_Code__c}`;
              } else {
                return "";
              }
            }}
            value={selectedTerritory}
            name="dealerId"
            onChange={(event, newValue) => {
              setSelectedTerritory(newValue);
              setSelectLatLong(dealerPlaceHolder);
              setDealerOption([]);
            }}
            renderInput={(params) => (
              <TextField {...params} placeholder="Select Territory" />
            )}
          />
        }

        {userType !== "SH7" && (
            <Autocomplete
              id="grouped-demo"
              className="myDealer__categorySelect"
              options={
                !_.isEmpty(tname) ? getUniqueListBy(tname, "SH2_Code__c") : []
              }
              getOptionLabel={(option) => {
                if (option?.SH2_Name__c) {
                  return `${option?.SH2_Name__c} : ${option?.SH2_Code__c}`;
                } else {
                  return "";
                }
              }}
              value={selectedTerritory}
              name="dealerId"
              onChange={(event, newValue) => {
                setSelectedTerritory(newValue);
                setSelectLatLong(dealerPlaceHolder);
                setDealerOption([]);
              }}
              renderInput={(params) => (
                <TextField {...params} placeholder="Select Territory" />
              )}
            />
          )}

        <Autocomplete
          id="grouped-demo2"
          className="myDealer__categorySelect"
          options={
            isDealerData === "Loading"
              ? [
                {
                  Dealer_Name__c: "Loading...",
                  DealerCode__c: "",
                },
              ]
              : isDealerData === "false" ? [
                {
                  Dealer_Name__c: "No Data",
                  DealerCode__c: "",
                },
              ] : dealerOption
          }
          getOptionLabel={(option) => {
            if (option?.Dealer_Name__c) {
              return `${option?.Dealer_Name__c} ${option?.DealerCode__c && ":"
                } ${option?.DealerCode__c}`;
            } else {
              return "";
            }
          }}
          name="subCategory"
          value={selectLatLong}
          onChange={(event, newValue) => {
            setSelectLatLong(newValue);
          }}
          renderInput={(params) => (
            <TextField {...params} placeholder="Select Dealer Name" />
          )}
        />

        {/* <PowerBi/> */}
        {/* google mao component here */}

        <div className={styles.myDealer__googleMap_Box}>
          {locationError ? (
            <Message
              message={
                "Location access denied. Please update your browser settings to grant location permission."
              }
              className="DealerLocatorForm__mapAccess"
              icon=<MdLocationOff
                style={{ color: "#808080", height: "2em", width: "2em" }}
              />
            />
          ) : (
            <GoogleMap
              lat={_.isEmpty(lat) ? currentLatitude : parseFloat(lat)}
              long={_.isEmpty(long) ? currentLongitude : parseFloat(long)}
              zoom={12}
            />
          )}
        </div>

        <div className="row m-0 p-2">
          <div className="col-4 ps-0">
            <Link to="#">
              <Button
                className={styles.myDealer__navButton}
                onClick={showInMapClicked}
              >
                Navigate
                <RiDirectionFill
                  style={{ marginLeft: "4px", marginBottom: "1px" }}
                />
              </Button>
            </Link>
          </div>
          <div className="col-4">
            <Button
              className={styles.myDealer__navButton}
              onClick={checkIn}
            >
              Check In
            </Button>
          </div>
          <div className="col-4 pe-0">
            <Link to="/past-sr-insight">
              <Button className={styles.myDealer__navButton}>
                SR/Observations
              </Button>
            </Link>
          </div>
        </div>
      </div>
      <CommonSnackbar
        open={failedOpen}
        autoHideDuration={3000}
        onClose={handleOnClose}
        anchorOrigin={{
          vertical: "top",
          horizontal: "center",
        }}
        severity={"error"}
        message={error}
      />

      <CommonSnackbar
        open={successOpen}
        autoHideDuration={3000}
        onClose={handleOnClose}
        anchorOrigin={{
          vertical: "top",
          horizontal: "center",
        }}
        severity={"success"}
        message={`Successfully checked-in for ${!_.isEmpty(dealer) ? dealer : "this"
          }${" "}
        dealer!`}
      />
      <div className="pbi__outerBox">
          <PowerBi
            id = "d4345e64-7c35-46f7-b844-8f2f5443dd2d"
            pageName = "ReportSection"
            dealerCode = {dealerCode}
          />
      </div>
    </>
  );
};

export default connect(null, {
  checkedInapi,
  getZSMTerritoryApi,
  getRmdmWssApi,
  getDealerNameApi,
})(MyDealers);
