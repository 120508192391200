import React from "react";
import { useState, useEffect } from "react";
import { Container } from "react-bootstrap";
import PageHeader from "../Components/Common/PageHeader";
import DRMPlanOne from "../Components/DRMPlanning/DRMPlanOne";
import Box from '@mui/material/Box';
import Tab from '@mui/material/Tab';
import dayjs from 'dayjs';
import TabContext from '@mui/lab/TabContext';
import TabList from '@mui/lab/TabList';
import TabPanel from '@mui/lab/TabPanel';
import Autocomplete from "@mui/material/Autocomplete";
import TextField from '@mui/material/TextField';
import { getAllMeeting } from "../services/DRM/getAllMeeting";
import { getMeetingDetails } from "../services/DRM/getMeetingDetails";
import { getTown } from "../services/DRM/getTown"
import "./DRMplanning.css"
import { useSelector } from "react-redux";
import { useLocation } from 'react-router-dom';
import QRCodeGenerator from "../Components/DRMPlanning/QRCodeGenerator"
import { countFeedback } from "../services/DRM/countFeedback";



const DRMplanning = () => {
  const [tabvalue, setTabValue] = React.useState('1');
  const [blockTab, setBlockTab] = useState(false)
  const [options, setOptions] = useState([])
  const [meetings, setMeetings] = useState([]);
  const [nsmstatus, setNsmStatus] = useState();
  const [nsmfeedback, setNsmFeedback] = useState();
  const [meetingdetails, setMeetingDetails] = useState();
  const [executiondisable, setExecutionDisable] = useState(false)
  const [disable, setDisable] = useState(false);
  const [QRvisible, setQRvisible] = useState(false);
  const [unableupdate, setUnableUpdate] = useState(true);
  const [errors, setErrors] = useState({});
  const [mindate, setMinDate] = useState();
  const [currentmeetingid, setCurrentMeetingid] = useState();
  const [triggermeetings, setTriggerMeetings] = useState(false)
  const [loading, setLoading] = useState(false);
  const [disableactualdate, setDisableActualDate] = useState(true);
  const [townname, setTownName] = useState("");
  const [startExecution, setStartExecution] = useState(false);
  const [disableUpdate, setDisableUpdate] = useState(false);
  const [townlist, setTownList] = useState([]);
  const [disableTown, setDisableTown] = useState(false);
  const [statusCompleted, setStatusCompleted] = useState(false);
  const [feedbackcount, setFeedbackCount] = useState(0);
  const [showReason, setShowReason] = useState(false);
  const [DisableSubmitDRM, setDisableSubmitDRM] = useState(false);
  const [failedOpen, setFailesOpen] = useState(false);
  const [snackerror, setSnackError] = useState("");


  const userEmailid = useSelector((state) => state?.userDetails?.details?.Email);
  const Sg = useSelector((state) => state?.userDetails?.details?.Sales_Group_Code__c);
  const cluster = useSelector((state) => state?.userDetails?.details?.SH_Code__c);
  const cluster_name = useSelector((state) => state?.userDetails?.details?.SH_Name__c);
  const zone = useSelector((state) => state?.userDetails?.details?.Division);

  const territoryOption = useSelector(
    (state) => state?.territory?.territoryDetails
  );


  useEffect(() => {
    try {
      const getTownFromCluster = async () => {
        const response = await getTown({ cluster })
        console.log("inside get town", response?.data?.records)
        if (response?.success) {
          setTownList(response?.data?.records)
        }


      }
      getTownFromCluster();

    }
    catch (error) {
      console.error('Error fetching meetings:', error);
    }

  }, [])


  // get meetingid from url
  const useQuery = () => {
    return new URLSearchParams(useLocation().search);
  };
  const query = useQuery();
  useEffect(() => {
    console.log("Cluster code", cluster)
    const meetingid = query.get('id');
    setCurrentMeetingid(meetingid)
  }, [])

  const SpecaObj = {
    "Division": "40",
    "SalesGroup": "401",
    "HierarchyCode": "CCMDNCA",
    "VerticalName": "401",
    "MeetingDate": "2023-12-29 00:00:00",
    "MeetingType": "CONTRACTORS MEET Test",
    "MeetingCode": "CC2349768",
    "CityName": "Ahmadabad",
    "TownCode": "UGJ00006",
    "PerHeadCost": "500",
    "InviteeCount": 85,
    "AttendeeCount": 85,
    "TotalAmount": "42500",
    "AttendeesPhoto": "()",
    "Billno": "0",
    "BillPhoto": "()",
    "ITrakToTEMFlag": "Y",
    "ITrakToTEMDate": "2024-01-02 00:00:00",
    "Attendee_KYCRecieved": 0,
    "Attendee_KYCNotRecieved": 0,
    "Attendee_Stakeholders": 4,
    "Attendee_Helper": 0,
    "Attendee_Repeat": "0",
    "MeetingFormat": "Physical Meet",
    "ADV_AND_PROMO": "",
    "EMPLOYEECODE": "00024845"

  }

  // setCurrentMeetingid(meetingid)
  ////////////////////////////////
  const initialFormData = {
    SG: Sg,
    Zone: zone,
    ClusterName: `${cluster} : ${cluster_name}`,
    TSITerritoryName: "",
    TownName: townname,
    // TownCode : "40564",
    // CustomerCode: "",
    CustomerName: "",
    Population: null,
    // TypeOfCompetitionMarket: "",
    // Quarter: "",
    ObjectiveOfMeet: null,
    TypeOfDRM: null,
    TownSelectionCriteria: null,
    DealerSelectionCriteria: null,
    IfCategorySpecificDealersThenWhichCategory: null,
    NoOfPCCdealersInTown: "",
    EstimatedNoOfDealersToParticipate: "",
    TotalHeadCountDealer: "",
    TotalHeadCountEmployee: "",
    TotalCost: "",
    CostPerHead: "",
    ReasonForCost: "",
    CurrentMonthlyAverageSalesOfTown: "",
    PostDRMMonthlyAverageSalePlannedForTheTown: "",
    TentativeDateOfTheMeet: null,
    ActualDateOfTheMeet: null,
    ActualNoOfDealersParticipated: null,
    ActualNoOfEmployeesParticipated: null,
    ActualCost: null,
    BMRemark: null,
    UserEmail: userEmailid,


  }

  const [formData, setFormData] = useState(initialFormData);
  const [specaObj, setSpecaObj] = useState(SpecaObj);


  useEffect(() => {

    const fetchCurrentMeeting = async () => {
      try {

        // const today = dayjs();
        // console.log("Todays Date",dayjs(today) )
        // // setMinDate(today.add(15, 'day'))
        setBlockTab(false)
        setExecutionDisable(false)
        setQRvisible(false)
        setDisable(false)
        setDisableTown(false)
        setErrors({})
        setStartExecution(false)
        setStatusCompleted(false)
        setDisableSubmitDRM(false)
        console.log("current meetingid", currentmeetingid)
        console.log("territoryOption -->>", territoryOption)
        if (currentmeetingid) {
          setLoading(true)
          const response = await getMeetingDetails({ currentmeetingid })


          console.log("meeting details-->>", response)
          if (response?.success) {
            setLoading(false)
            response?.data?.records[0]?.Cost_Per_Head__c > 1000 ? setShowReason(true) : setShowReason(false);
            if (response?.data?.records[0].NSM_Status__c == "Rejected" || response?.data?.records[0].ZSM_Status__c == "Rejected") {
              setUnableUpdate(false)
              setDisableTown(true)
            }
            else {
              setUnableUpdate(true)
            }
            response?.data?.records[0].NSM_Status__c == "Rejected" || response?.data?.records[0].ZSM_Status__c == "Rejected" ? setUnableUpdate(false) : setUnableUpdate(true)
            // response?.data?.records[0].NSM_Status__c == "Pending" && response?.data?.records[0].ZSM_Status__c == "Pending" ? setDisable(true) : setDisable(false);
            if((response?.data?.records[0].NSM_Status__c == "Pending" && response?.data?.records[0].ZSM_Status__c == "Pending") || (response?.data?.records[0].NSM_Status__c == "Pending" && response?.data?.records[0].ZSM_Status__c == "Approved"))
            {
              setDisable(true)
            }
            else {
              setDisable(false)
            }
            setNsmStatus(response?.data?.records[0]?.NSM_Status__c);
            setNsmFeedback(response?.data?.records[0]?.NSM_Feedback__c)
            // if(response?.data?.records[0].NSM_Status__c == null)
            // {
            //     setNsmStatus("Pending")
            //     setDisable(true)
            // } 
            // else{            
            //     setNsmStatus(response?.data?.records[0]?.NSM_Status__c)
            // }   

            if (response?.data?.records[0]?.NSM_Status__c == "Approved") {

              setDisable(true)
              setDisableActualDate(false)
              setQRvisible(true)
              setUnableUpdate(false)

              console.log("hello", response?.data?.records[0]?.Actual_Date_of_the_Meet__c)
              // if (dayjs().format('YYYY-MM-DD') >= response?.data?.records[0]?.Actual_Date_of_the_Meet__c)
              // {
              //   setBlockTab(true);
              //   setExecutionDisable(true)
              // }

            }
            else if (response?.data?.records[0]?.NSM_Status__c == "Completed") {
              setDisable(true)
              setDisableActualDate(true)
              setQRvisible(true)
              setUnableUpdate(false)
              setDisableUpdate(false)
              setStatusCompleted(true)
              setStartExecution(true)
              setDisableSubmitDRM(true)
              const response = await countFeedback({ currentmeetingid })
              console.log("count feedback Response", response)
              setFeedbackCount(response?.data[0]?.count)
            }
            setMeetingDetails(response?.data?.records[0])

          }
          else {
            setFailesOpen(true)
            setSnackError(response?.msg);
            setLoading(false)
          }

        }
        // const response = await getAllMeeting({userEmailid});
        // console.log('response getAllMeeting-->>', response.data);
        // const transformedOptions = response.data.records.map(record => ({
        //     label: record.Id,
        //     value: record.CreatedDate
        //   }));
        // console.log("transformedOptions-->>",transformedOptions)  
        // setOptions(transformedOptions)  
      } catch (error) {
        console.error('Error fetching meetings:', error);
        setFailesOpen(true)
        setSnackError("Error fetching meetings..");
      }
    };

    fetchCurrentMeeting();
  }, [currentmeetingid]);



    // const handleMeetingChange = async(name,newMeetingID) => {
    //     setBlockTab(false)
    //     setExecutionDisable(false)
    //     setDisable(false)
    //     setErrors({})
    //     console.log("newMeetingID-->>",newMeetingID)
    //     const response = await getMeetingDetails({newMeetingID})
    //     console.log("meeting details-->>",response?.data?.records[0])
    //     response?.data?.records[0].NSM_Status__c == "Rejected" ? setUnableUpdate(false) : setUnableUpdate(true)
    //     if(response?.data?.records[0].NSM_Status__c == null)
    //     {
    //         setNsmStatus("Pending")
    //         setDisable(true)
    //     } 
    //     else{            
    //         setNsmStatus(response?.data?.records[0].NSM_Status__c)
    //     }   
    //     setNsmFeedback(response?.data?.records[0].NSM_Feedback__c)
    //     if(response?.data?.records[0]?.NSM_Status__c == "Approved")
    //      {
    //         setBlockTab(true);
    //         setExecutionDisable(true)
    //         setDisable(true)
    //      }   
    //     setMeetingDetails(response?.data?.records[0])
    // }
    const handleChange = (event, newValue) => {
        console.log("blockTab",newValue)
        if(blockTab == true)
        {
            setTabValue(newValue)
        }           
      };
    return ( <div className="drmPlanning__OuterBox">
        <PageHeader title= {currentmeetingid !== null ? "DRM Planning" : "Create New DRM"} path="/DRM-Summary" />
        <Container style={{ backgroundColor: "#F5F5F5" }} className="mt-0">
        
         {/* <Autocomplete
          disablePortal
          className="input_meeting"
          id="combo-box-demo"
          options= {options}
          getOptionLabel={(option) => option.label}
          style={{ width: '100%' }}
          onChange= {(event,newMeetingID) => handleMeetingChange("hello",newMeetingID.label)}
          renderInput={(params) => <TextField {...params} label="Select Meeting" />}
         />
         <div className="status_wrap">
         <div className="col-6">
              <h6 className="DRM_Status__lable">
                NSM status : <p style={{ color: 'green' }}>{nsmstatus}</p> 
              </h6>
         </div>
         <div className="col-6">
              <h6 className="DRM_Status__lable">
                NSM Feedback : <p style={{ color: 'green' }}>{nsmfeedback}</p> 
              </h6>
         </div>
         </div> */}
      <Box sx={{ width: '100%', typography: 'body1' }}>
        <TabContext value={tabvalue}>
          <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
            <TabList onChange={handleChange} aria-label="lab API tabs example">
              <Tab label="DRM" value="1" />
              {blockTab && <Tab label="QR Code" value="2" />}

            </TabList>
          </Box>
          <TabPanel value="1"><DRMPlanOne failedOpen={failedOpen} setFailesOpen={setFailesOpen} snackerror={snackerror} setSnackError={setSnackError} DisableSubmitDRM={DisableSubmitDRM} setDisableSubmitDRM={setDisableSubmitDRM} showReason={showReason} setShowReason={setShowReason} setBlockTab={setBlockTab} feedbackcount={feedbackcount} statusCompleted={statusCompleted} disableTown={disableTown} townlist={townlist} setDisableUpdate={setDisableUpdate} disableUpdate={disableUpdate} setSpecaObj={setSpecaObj} specaObj={specaObj} setTabValue={setTabValue} startExecution={startExecution} setStartExecution={setStartExecution} setUnableUpdate={setUnableUpdate} setMeetingDetails={setMeetingDetails} setExecutionDisable={setExecutionDisable} QRvisible={QRvisible} setTownName={setTownName} setDisable={setDisable} territoryOption={territoryOption} disableactualdate={disableactualdate} setDisableActualDate={setDisableActualDate} loading={loading} mindate={mindate} setErrors={setErrors} setTriggerMeetings={setTriggerMeetings} errors={errors} meetingdetails={meetingdetails} executiondisable={executiondisable} disable={disable} unableupdate={unableupdate} formData={formData} setFormData={setFormData} initialFormData={initialFormData} setLoading={setLoading} /></TabPanel>
          <TabPanel value="2"><QRCodeGenerator meetingdetails={meetingdetails} /></TabPanel>

        </TabContext>
      </Box>

    </Container>
  </div>);
}

export default DRMplanning;