import {
  GET_GLOBAL_ACCESS_TOKEN_FAIL,
  GET_GLOBAL_ACCESS_TOKEN_SUCCESS,
  GET_PBI_ACCESS_TOKEN_FAIL,
  GET_PBI_ACCESS_TOKEN_SUCCESS,
  GET_USER_ACCESS_TOKEN_FAIL,
  GET_USER_ACCESS_TOKEN_SUCCESS,
  GET_USER_DETAILS_SUCCESS,
  GET_USER_DETAILS_FAIL,
  GET_TERRITORY_SUCCESS,
  GET_TERRITORY_FAIL,
  LOGOUT,
  REMOVE_ALERT,
  SET_ALERT,
  GET_QUERY_DETAILS_FAIL,
  GET_QUERY_DETAILS_SUCCESS,
  GET_MARKET_WORKING_SUCCESS,
  GET_MARKET_WORKING_FAIL,
  GET_BAR_GRAPH_SUCCESS,
  GET_BAR_GRAPH_FAIL,
  GET_CHECKIN_DETAILS_SUCCESS,
  GET_CHECKIN_DETAILS_FAIL,
  GET_UPCOMING_TOUR_PLAN_SUCCESS,
  GET_UPCOMING_TOUR_PLAN_FAIL,
  GET_PASTINSIGHTS_DETAILS_SUCCESS,
  GET_PASTINSIGHTS_DETAILS_FAIL,
  GET_FEEDBACK_DETAILS_FAIL,
  GET_FEEDBACK_DETAILS_SUCCESS,
  GET_DIVISION_GROUP_SUCCESS,
  GET_DIVISION_GROUP_FAIL,
  GET_USER_DETAILS_EMPTY,
  GET_SECONDARY_SALES_ACHIEVED_SUCCESS,
  GET_SECONDARY_SALES_TREND_SUCCESS,
  GET_SECONDARY_SALES_ACHIEVED_FAIL,
  GET_SECONDARY_SALES_TREND_FAIL,
  GET_SECONDARY_SALES_TARGET_ACHIEVED_SUCCESS,
  GET_SECONDARY_SALES_TARGET_ACHIEVED_FAIL,
  GET_SECONDARY_SALES_TARGET_TREND_SUCCESS,
  GET_SECONDARY_SALES_TARGET_TREND_FAIL,
  GET_TEAMS_AVERAGE_TSI_SCORE_FAIL,
  GET_TEAMS_AVERAGE_TSI_SCORE_TREND_FAIL,
  GET_TEAMS_AVERAGE_TSI_SCORE_SUCCESS,
  GET_TEAMS_AVERAGE_TSI_SCORE_TREND_SUCCESS,
  SET_SELECTED_INSIGHTS,
  GET_TEAMS_AVERAGE_TSR_SCORE_TREND_SUCCESS,
  GET_TEAMS_AVERAGE_TSR_SCORE_SUCCESS,
  GET_TEAMS_AVERAGE_TSR_SCORE_FAIL,
  GET_TEAMS_AVERAGE_TSR_SCORE_TREND_FAIL,
  GET_TEAMS_INCENTIVE_SUCCESS,
  GET_TEAMS_INCENTIVE_FAIL,
  GET_TEAMS_INCENTIVE_TREND_SUCCESS,
  GET_TEAMS_INCENTIVE_TREND_FAIL,
  GET_TEAMS_SOP_SUCCESS,
  GET_TEAMS_SOP_TREND_SUCCESS,
  GET_TEAMS_SOP_FAIL,
  GET_TEAMS_SOP_TREND_FAIL,
  GET_WSS_AUTO_VALUE_FTD_SUCCESS,
  GET_WSS_AUTO_VALUE_FTD_TREND_SUCCESS,
  GET_WSS_AUTO_VALUE_FTD_FAIL,
  GET_WSS_AUTO_VALUE_FTD_TREND_FAIL,
  GET_WSS_WSS_IN_BLOCK_SUCCESS,
  GET_WSS_WSS_IN_BLOCK_FAIL,
  GET_WSS__DARPAN_SYNC_DAYS_EXCEPTION_SUCCESS,
  GET_WSS_DARPAN_SYNC_DAYS_EXCEPTION_FAIL,
  GET_CLUSTER_CODE_FAIL,
  GET_CLUSTER_CODE_SUCCESS,
  CURRENT_DAY_TOUR_ASM_DATA_FAIL,
  CURRENT_DAY_TOUR_ASM_DATA_SUCCESS,
  CURRENT_DAY_TOUR_TSI_DATA_SUCCESS,
  CURRENT_DAY_TOUR_TSI_DATA_FAIL,
  GET_HO_SH7_Code_FAIL,
  GET_HO_SH7_Code_SUCCESS,
  GET_OUTLETS_BILLED_FTM_SUCCESS,
  GET_OUTLETS_BILLED_FTM_TREND_SUCCESS,
  GET_RSM_FTM_SUCCESS,
  GET_RSM_FTM_TREND_SUCCESS,
  GET_OUTLETS_BILLED_FTM_FAIL,
  GET_OUTLETS_BILLED_FTM_TREND_FAIL,
  GET_RSM_FTM_TREND_FAIL,
  GET_RSM_FTM_FAIL,
  GET_HO_Nsm_Email_SUCCESS,
  GET_HO_Nsm_Email_FAIL,
  GET_PRODUCT_SUCCESS,
  GET_PRODUCT_FAIL,
  GET_WSS_CHECKIN_DETAILS_SUCCESS,
  GET_WSS_CHECKIN_DETAILS_FAIL,
  GET_MY_USER_FEEDBACK_SUCCESS,
  GET_MY_USER_FEEDBACK_FAIL,
  GET_RMDM_TOWN_FAIL,
  GET_RMDM_TOWN_SUCCESS,
  GET_MVR_DRAFT_SUCCESS,
  REMOVE_MVR_DRAFT_SUCCESS,
  GET_BM_WSS_SUCCESS,
  GET_BM_WSS_FAIL,
  GET_SALESGRUOP_USER_SUCCESS,
  GET_SALESGRUOP_USER_FAIL,
  GET_RBDM_CODE_SUCCESS,
  GET_RBDM_CODE_FAIL,
  GET_USER_TYPE_SUCCESS,
  GET_USER_TYPE_FAIL,
  GET_WSS_CODE_SUCCESS,
  GET_WSS_CODE_FAIL,
  GET_TOWN_CODE_SUCCESS,
  GET_TOWN_CODE_FAIL,
  SET_SESSIONALERT_SUCCESS,
  SET_SESSIONALERT_FAIL,
  SET_CHECK_IN_FLAG_SUCCESS,
  SET_CHECK_IN_FLAG_FAIL
} from "../constants/action-types";

const initialTokenStates = {
  globalAccessToken: "",
  accessToken: "",
  sessionId: "",
  isAuthenticated: null,
  creationDate: "",
  loading: true,
};

const initialAlertState = [];

const initialPBIStates = {
  pbiAccessToken: "",
};

const initialUserState = {
  details: "",
};
const initialTerritoryState = {
  territoryDetails: [],
};

const initialProductState = {
  productList: [],
};

const initialQueryDetailState = {
  queryDetails: [],
};

const initialMarketWorkingState = {
  marketWorkingDetails: [],
};

const initialBarGraphState = {
  graph: [],
};

const initialVisitDetailsState = {
  checkInDetails: [],
  checkInWSSDetails: [],
};

const initialUpcomingTourPlanState = {
  upcomingTourDetails: [],
};
const initialPastInsightState = {
  pastInsightDetails: [],
};

const initialgetFeedbackState = {
  getFeedbackDetails: [],
};

const initialDivisionGroupState = {
  getDivisionGroup: [],
};

const initialDashboardState = {
  secondarySalesAcheived: [],
  trend: [],
  secondarySalesTagetAcheived: [],
  targetTrend: [],
  teamsAverageTsiScore: [],
  teamsAverageTsiScoreTrend: [],
  teamsIncentive: [],
  teamsIncentiveTrend: [],
  teamsSop: [],
  teamsSopTrend: [],
  wssAutoValueFtd: [],
  wssAutoValueFtdTrend: [],
  wssWssInBlockCount: [],
  wssDarpanSyncDayExceptionCount: [],
  outletsBilledFtmCount: [],
  outletsBilledFtmCountTrend: [],
  rsmFtmSum: [],
  rsmFtmSumTrend: [],
};

const initialInsightsState = {
  selectedInsights: [],
};

const initialClusterCode = { clusterCodeList: [] };
const initialWssCode = { wssCodeList: [] };
const initialTownCode = { townCodeList: [] };

const initialCurrentDayTour = {
  currentDayTourList: [],
  loading: true,
};

const initialUserFeeback = {
  userList: [],
};

const initialSH7CodeForHo = {
  sh7Code: "",
  nsmEmail: "",
};

const initialRmdmTown = { townList: [] };

const initialMvrData = { draft: {} };

const initialBmWss = { wssList: [] };

const initialSalesgruopUsers = { salesgroupUserList: [] };

const initialRBDMCode = { RBDMCode: [] };

const initialUserTypeState = {
  type: "",
};

const initialSessionAlertState = { Alert : ""};

const initialWssCountFlagState = { wssCountFlag: "" };

export const userDetailsReducer = (
  state = initialUserState,
  { type, payload }
) => {
  switch (type) {
    case GET_USER_DETAILS_SUCCESS:
      return {
        ...state,
        details: payload,
      };

    case GET_USER_DETAILS_FAIL:
    case GET_USER_DETAILS_EMPTY:
      return {
        ...state,
        details: "",
      };
    default:
      return state;
  }
};

export const territoryDetailsReducer = (
  state = initialTerritoryState,
  { type, payload }
) => {
  switch (type) {
    case GET_TERRITORY_SUCCESS:
      return {
        ...state,
        territoryDetails: payload,
      };

    case GET_TERRITORY_FAIL:
      return {
        ...state,
        territoryDetails: [],
      };
    default:
      return state;
  }
};

export const productReducer = (
  state = initialProductState,
  { type, payload }
) => {
  switch (type) {
    case GET_PRODUCT_SUCCESS:
      return {
        ...state,
        productList: payload,
      };

    case GET_PRODUCT_FAIL:
      return {
        ...state,
        productList: [],
      };
    default:
      return state;
  }
};

export const tokenReducer = (state = initialTokenStates, { type, payload }) => {
  switch (type) {
    case GET_GLOBAL_ACCESS_TOKEN_SUCCESS:
      return {
        ...state,
        globalAccessToken: payload,
        accessToken: "",
        isAuthenticated: false,
        creationDate: "",
        loading: false,
      };

    case GET_USER_ACCESS_TOKEN_SUCCESS:
      return {
        ...state,
        accessToken: payload.accessToken,
        globalAccessToken: "",
        isAuthenticated: true,
        creationDate: payload.creationDate,
        loading: false,
      };

    case GET_GLOBAL_ACCESS_TOKEN_FAIL:
    case GET_USER_ACCESS_TOKEN_FAIL:
      return {
        ...state,
        accessToken: "",
        globalAccessToken: "",
        isAuthenticated: false,
        creationDate: "",
        loading: false,
      };

    case LOGOUT:
      localStorage?.removeItem("pop_status");
      return {
        ...state,
        accessToken: "",
        globalAccessToken: "",
        isAuthenticated: false,
        creationDate: "",
        loading: true,
      };

    // case ActionTypes.SET_SESSION_ID:
    //     return { ...state, sessionId: payload };
    default:
      return state;
  }
};

export const pbiTokenReducer = (
  state = initialPBIStates,
  { type, payload }
) => {
  switch (type) {
    case GET_PBI_ACCESS_TOKEN_SUCCESS:
      return {
        ...state,
        pbiAccessToken: payload,
      };

    case GET_PBI_ACCESS_TOKEN_FAIL:
      return {
        ...state,
        pbiAccessToken: "",
      };

    default:
      return state;
  }
};

export const alertReducer = (state = initialAlertState, { type, payload }) => {
  switch (type) {
    case SET_ALERT:
      return [...state, payload];
    case REMOVE_ALERT:
      return state.filter((alert) => alert.id !== payload);
    default:
      return state;
  }
};

export const queryDetailsReducer = (
  state = initialQueryDetailState,
  { type, payload }
) => {
  switch (type) {
    case GET_QUERY_DETAILS_SUCCESS:
      return {
        ...state,
        queryDetails: payload,
      };

    case GET_QUERY_DETAILS_FAIL:
      return {
        ...state,
        queryDetails: "",
      };
    default:
      return state;
  }
};

export const marketWorkingReducer = (
  state = initialMarketWorkingState,
  { type, payload }
) => {
  switch (type) {
    case GET_MARKET_WORKING_SUCCESS:
      return {
        ...state,
        marketWorkingDetails: payload,
      };

    case GET_MARKET_WORKING_FAIL:
      return {
        ...state,
        marketWorkingDetails: "",
      };
    default:
      return state;
  }
};

export const barGraphReducer = (
  state = initialBarGraphState,
  { type, payload }
) => {
  switch (type) {
    case GET_BAR_GRAPH_SUCCESS:
      return {
        ...state,
        graph: payload,
      };

    case GET_BAR_GRAPH_FAIL:
      return {
        ...state,
        graph: "",
      };
    default:
      return state;
  }
};

export const visitDetailsReducer = (
  state = initialVisitDetailsState,
  { type, payload }
) => {
  switch (type) {
    case GET_CHECKIN_DETAILS_SUCCESS:
      return {
        ...state,
        checkInDetails: payload,
      };

    case GET_CHECKIN_DETAILS_FAIL:
      return {
        ...state,
        checkInDetails: "",
      };
    case GET_WSS_CHECKIN_DETAILS_SUCCESS:
      return {
        ...state,
        checkInWSSDetails: payload,
      };

    case GET_WSS_CHECKIN_DETAILS_FAIL:
      return {
        ...state,
        checkInWSSDetails: "",
      };
    default:
      return state;
  }
};

export const upcomingTourPlanReducer = (
  state = initialUpcomingTourPlanState,
  { type, payload }
) => {
  switch (type) {
    case GET_UPCOMING_TOUR_PLAN_SUCCESS:
      return {
        ...state,
        upcomingTourDetails: payload,
      };

    case GET_UPCOMING_TOUR_PLAN_FAIL:
      return {
        ...state,
        upcomingTourDetails: "",
      };
    default:
      return state;
  }
};

export const pastInsightReducer = (
  state = initialPastInsightState,
  { type, payload }
) => {
  switch (type) {
    case GET_PASTINSIGHTS_DETAILS_SUCCESS:
      return {
        ...state,
        pastInsightDetails: payload,
      };

    case GET_PASTINSIGHTS_DETAILS_FAIL:
      return {
        ...state,
        pastInsightDetails: "",
      };
    default:
      return state;
  }
};

export const getFeedbackReducer = (
  state = initialgetFeedbackState,
  { type, payload }
) => {
  switch (type) {
    case GET_FEEDBACK_DETAILS_SUCCESS:
      return {
        ...state,
        getFeedbackDetails: payload,
      };

    case GET_FEEDBACK_DETAILS_FAIL:
      return {
        ...state,
        getFeedbackDetails: "",
      };
    default:
      return state;
  }
};

export const getDivisionGroupReducer = (
  state = initialDivisionGroupState,
  { type, payload }
) => {
  switch (type) {
    case GET_DIVISION_GROUP_SUCCESS:
      return {
        ...state,
        getDivisionGroup: payload,
      };

    case GET_DIVISION_GROUP_FAIL:
      return {
        ...state,
        getDivisionGroup: "",
      };
    default:
      return state;
  }
};

export const getDashboardReducer = (
  state = initialDashboardState,
  { type, payload }
) => {
  switch (type) {
    case GET_SECONDARY_SALES_ACHIEVED_SUCCESS:
      return {
        ...state,
        secondarySalesAcheived: payload,
      };

    case GET_SECONDARY_SALES_TREND_SUCCESS:
      return {
        ...state,
        trend: payload,
      };

    case GET_SECONDARY_SALES_TARGET_ACHIEVED_SUCCESS:
      return {
        ...state,
        secondarySalesTagetAcheived: payload,
      };

    case GET_SECONDARY_SALES_TARGET_TREND_SUCCESS:
      return {
        ...state,
        targetTrend: payload,
      };

    case GET_TEAMS_AVERAGE_TSI_SCORE_SUCCESS:
      return {
        ...state,
        teamsAverageTsiScore: payload,
      };

    case GET_TEAMS_AVERAGE_TSI_SCORE_TREND_SUCCESS:
      return {
        ...state,
        teamsAverageTsiScoreTrend: payload,
      };

    case GET_TEAMS_AVERAGE_TSR_SCORE_SUCCESS:
      return {
        ...state,
        teamsAverageTsrScore: payload,
      };

    case GET_TEAMS_AVERAGE_TSR_SCORE_TREND_SUCCESS:
      return {
        ...state,
        teamsAverageTsrScoreTrend: payload,
      };
    case GET_WSS_AUTO_VALUE_FTD_SUCCESS:
      return {
        ...state,
        wssAutoValueFtd: payload,
      };

    case GET_WSS_AUTO_VALUE_FTD_TREND_SUCCESS:
      return {
        ...state,
        wssAutoValueFtdTrend: payload,
      };

    case GET_WSS_WSS_IN_BLOCK_SUCCESS:
      return {
        ...state,
        wssWssInBlockCount: payload,
      };

    case GET_WSS__DARPAN_SYNC_DAYS_EXCEPTION_SUCCESS:
      return {
        ...state,
        wssDarpanSyncDayExceptionCount: payload,
      };

    case GET_TEAMS_INCENTIVE_SUCCESS:
      return {
        ...state,
        teamsIncentive: payload,
      };

    case GET_TEAMS_INCENTIVE_TREND_SUCCESS:
      return {
        ...state,
        teamsIncentiveTrend: payload,
      };

    case GET_TEAMS_SOP_SUCCESS:
      return {
        ...state,
        teamsSop: payload,
      };

    case GET_TEAMS_SOP_TREND_SUCCESS:
      return {
        ...state,
        teamsSopTrend: payload,
      };
    case GET_OUTLETS_BILLED_FTM_SUCCESS:
      return {
        ...state,
        outletsBilledFtmCount: payload,
      };

    case GET_OUTLETS_BILLED_FTM_TREND_SUCCESS:
      return {
        ...state,
        outletsBilledFtmCountTrend: payload,
      };

    case GET_RSM_FTM_SUCCESS:
      return {
        ...state,
        rsmFtmSum: payload,
      };
    case GET_RSM_FTM_TREND_SUCCESS:
      return {
        ...state,
        rsmFtmSumTrend: payload,
      };

    case GET_SECONDARY_SALES_ACHIEVED_FAIL:
    case GET_SECONDARY_SALES_TREND_FAIL:
    case GET_SECONDARY_SALES_TARGET_ACHIEVED_FAIL:
    case GET_SECONDARY_SALES_TARGET_TREND_FAIL:
    case GET_TEAMS_AVERAGE_TSI_SCORE_FAIL:
    case GET_TEAMS_AVERAGE_TSI_SCORE_TREND_FAIL:
    case GET_TEAMS_AVERAGE_TSR_SCORE_FAIL:
    case GET_TEAMS_AVERAGE_TSR_SCORE_TREND_FAIL:
    case GET_TEAMS_INCENTIVE_FAIL:
    case GET_TEAMS_INCENTIVE_TREND_FAIL:
    case GET_TEAMS_SOP_FAIL:
    case GET_TEAMS_SOP_TREND_FAIL:
    case GET_WSS_AUTO_VALUE_FTD_FAIL:
    case GET_WSS_AUTO_VALUE_FTD_TREND_FAIL:
    case GET_WSS_WSS_IN_BLOCK_FAIL:
    case GET_WSS_DARPAN_SYNC_DAYS_EXCEPTION_FAIL:
    case GET_OUTLETS_BILLED_FTM_FAIL:
    case GET_OUTLETS_BILLED_FTM_TREND_FAIL:
    case GET_RSM_FTM_TREND_FAIL:
    case GET_RSM_FTM_FAIL:
      return {
        ...state,
        secondarySalesAcheived: [],
        trend: [],
        secondarySalesTagetAcheived: [],
        targetTrend: [],
        teamsAverageTsiScoreTrend: [],
        teamsAverageTsiScore: [],
        teamsAverageTsrScore: [],
        teamsAverageTsrScoreTrend: [],
        teamsIncentive: [],
        teamsIncentiveTrend: [],
        teamsSop: [],
        teamsSopTrend: [],
        wssAutoValueFtd: [],
        wssAutoValueFtdTrend: [],
        wssAutoValueFtdTrend: [],
        wssDarpanSyncDayExceptionCount: [],
        outletsBilledFtmCount: [],
        outletsBilledFtmCountTrend: [],
        rsmFtmSum: [],
        rsmFtmSumTrend: [],
      };
    default:
      return state;
  }
};

export const insightsReducer = (
  state = initialInsightsState,
  { type, payload }
) => {
  switch (type) {
    case SET_SELECTED_INSIGHTS:
      return {
        ...state,
        selectedInsights: payload,
      };
    default:
      return state;
  }
};

export const clusterCodeReducer = (
  state = initialClusterCode,
  { type, payload }
) => {
  switch (type) {
    case GET_CLUSTER_CODE_SUCCESS:
      return {
        ...state,
        clusterCodeList: payload,
      };
    case GET_CLUSTER_CODE_FAIL:
      return {
        ...state,
        clusterCodeList: [],
      };
    default:
      return state;
  }
};

export const wssOptionsReducer = (
  state = initialWssCode,
  { type, payload }
) => {
  switch (type) {
    case GET_WSS_CODE_SUCCESS:
      return {
        ...state,
        wssCodeList: payload,
      };
    case GET_WSS_CODE_FAIL:
      return {
        ...state,
        wssCodeList: [],
      };
    default:
      return state;
  }
};

export const townOptionsReducer = (
  state = initialTownCode,
  { type, payload }
) => {
  switch (type) {
    case GET_TOWN_CODE_SUCCESS:
      return {
        ...state,
        townCodeList: payload,
      };
    case GET_TOWN_CODE_FAIL:
      return {
        ...state,
        townCodeList: [],
      };
    default:
      return state;
  }
};

export const currentDayTourReducer = (
  state = initialCurrentDayTour,
  { type, payload }
) => {
  switch (type) {
    case CURRENT_DAY_TOUR_ASM_DATA_SUCCESS:
      return {
        ...state,
        currentDayTourList: payload,
        loading: false,
      };

    case CURRENT_DAY_TOUR_ASM_DATA_FAIL:
      return {
        ...state,
        currentDayTourList: [],
        loading: false,
      };

    default:
      return state;
  }
};

export const HoUserSh7CodeReducer = (
  state = initialSH7CodeForHo,
  { type, payload }
) => {
  switch (type) {
    case GET_HO_SH7_Code_SUCCESS:
      return {
        ...state,
        sh7Code: payload,
      };

    case GET_HO_SH7_Code_FAIL:
      return {
        ...state,
        sh7Code: "",
      };

    case GET_HO_Nsm_Email_SUCCESS:
      return {
        ...state,
        nsmEmail: payload,
      };

    case GET_HO_Nsm_Email_FAIL:
      return {
        ...state,
        nsmEmail: "",
      };

    default:
      return state;
  }
};

export const myUserFeedbackReducer = (
  state = initialUserFeeback,
  { type, payload }
) => {
  switch (type) {
    case GET_MY_USER_FEEDBACK_SUCCESS:
      return {
        ...state,
        userList: payload,
      };

    case GET_MY_USER_FEEDBACK_FAIL:
      return {
        ...state,
        userList: [],
      };

    default:
      return state;
  }
};

export const rmdmTownReducer = (state = initialRmdmTown, { type, payload }) => {
  switch (type) {
    case GET_RMDM_TOWN_SUCCESS:
      return {
        ...state,
        townList: payload,
      };
    case GET_RMDM_TOWN_FAIL:
      return {
        ...state,
        townList: [],
      };
    default:
      return state;
  }
};

export const mvrDraftReducer = (state = initialMvrData, { type, payload }) => {
  switch (type) {
    case GET_MVR_DRAFT_SUCCESS:
      return {
        ...state,
        draft: payload,
      };

    case REMOVE_MVR_DRAFT_SUCCESS:
      return {
        ...state,
        draft: payload,
      };

    default:
      return state;
  }
};

export const bmWssReducer = (state = initialBmWss, { type, payload }) => {
  switch (type) {
    case GET_BM_WSS_SUCCESS:
      return {
        ...state,
        wssList: payload,
      };
    case GET_BM_WSS_FAIL:
      return {
        ...state,
        wssList: [],
      };
    default:
      return state;
  }
};

export const salesgroupUserReducer = (
  state = initialSalesgruopUsers,
  { type, payload }
) => {
  switch (type) {
    case GET_SALESGRUOP_USER_SUCCESS:
      return {
        ...state,
        salesgroupUserList: payload,
      };
    case GET_SALESGRUOP_USER_FAIL:
      return {
        ...state,
        salesgroupUserList: [],
      };
    default:
      return state;
  }
};

export const RBDMCodeReducer = (state = initialRBDMCode, { type, payload }) => {
  switch (type) {
    case GET_RBDM_CODE_SUCCESS:
      return {
        ...state,
        RBDMCode: payload,
      };
    case GET_RBDM_CODE_FAIL:
      return {
        ...state,
        RBDMCode: [],
      };
    default:
      return state;
  }
};

export const getUserTypeReducer = (
  state = initialUserTypeState,
  { type, payload }
) => {
  switch (type) {
    case GET_USER_TYPE_SUCCESS:
      return {
        ...state,
        type: payload,
      };

    case GET_USER_TYPE_FAIL:
      return {
        ...state,
        type: "",
      };
    default:
      return state;
  }
};


export const setSessionAlertReducer = (
  state = initialSessionAlertState,
  { type, payload }
) => {
  switch (type) {
    case SET_SESSIONALERT_SUCCESS:
      return {
        ...state,
        Alert: payload,
      };

    case SET_SESSIONALERT_FAIL:
      return {
        ...state,
        Alert: "",
      };
    default:
      return state;
  }
};


export const setCheckInFlagReducer = (
  state = initialWssCountFlagState,
  { type, payload }
) => {
  switch (type) {
    case SET_CHECK_IN_FLAG_SUCCESS:
      return {
        ...state,
        wssCountFlag: payload,
      };

    case SET_CHECK_IN_FLAG_FAIL:
      return {
        ...state,
        wssCountFlag: "",
      };
    default:
      return state;
  }
};
