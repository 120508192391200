import axios from "axios";
import { BASE_URL } from "../variables";

export const forgotPasswordApi = async ({ globalAccessToken, emailId }) => {
  const headers = {
    "Content-Type": "application/json",
    "Access-Control-Allow-Origin": "*",
    Authorization: `Bearer ${globalAccessToken}`,
  };

  const options = {
    url: `${BASE_URL}/services/apexrest/Reset_Password/`,
    method: "post",
    headers: headers,
    data: {
      username: emailId,
    },
  };
  try {
    const response = await axios(options);
    if (response?.data.success === true) {
      return {
        success: true,
        data: response?.data?.message,
      };
    } else {
      return {
        success: true,
        data: response?.data?.message,
      };
    }
  } catch (err) {
    return {
      success: false,
      msg: "Somthing Went Wrong..!!",
    };
  }
};
